import { $win } from '../utils/globals';
import { $document } from '../utils/globals';
import { $mainNavLinks } from '../utils/globals';

function navSubmenu($navLink) {
	$navLink.on('click', function(e) {
		const $parent = $(this).parent();
		const $siblings = $parent.siblings();
		const isParentHasChildren = $parent.is('.menu-item-has-children');
		const isParentExpanded = $parent.is('.menu-item-has-children.is-expanded');
		const isSiblingExpanded = $siblings.is('.menu-item-has-children.is-expanded');

		if (! isParentHasChildren && ! isSiblingExpanded) {
			return;
		}

		if (isParentHasChildren && isParentExpanded) {
			return;
		}

		if (! isParentHasChildren && isSiblingExpanded) {
			e.preventDefault();

			if (window.innerWidth > 1024) {
				$siblings.removeClass('is-expanded');
			} else {
				$siblings.removeClass('is-expanded').children('ul').stop().slideUp();
			}

			return;
		}

		e.preventDefault();
		
		if (window.innerWidth > 1024) {
			$siblings.removeClass('is-expanded');
			$parent.addClass('is-expanded');
		} else {
			$siblings.removeClass('is-expanded').children('ul').stop().slideUp();
			$parent.addClass('is-expanded').children('ul').stop().slideDown();
		}
	});

	$document.on('click', function(e) {
		e.stopPropagation()

		const $eTarget = $(e.target);
		const returnCondition = $eTarget.is('nav a') || $eTarget.closest('.nav ul').length;

		if (returnCondition) {
			return;
		}

		$mainNavLinks.parent().removeClass('is-expanded');
	});
}

$win.on('load resize', function() {
	navSubmenu($mainNavLinks);
});
