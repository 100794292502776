/*
 * Polyfills
 */
import './polyfills/object-fit';

/*
 * Modules
 */

import './modules/header-form';
import './modules/nav-trigger';
import './modules/nav-submenu';
import './modules/sticky-sidebar';
import './modules/review-form';
import './modules/scroll-to-reviews';
import './modules/scroll-to-section';