function slideReviewForm() {
	$('.js-slide-review-form').on('click', function(e) {
		e.preventDefault();

		const $this = $(this);

		$this.closest('.reviews__head').prev().stop().slideToggle();

		$('html, body').animate({
			scrollTop: $this.closest('.reviews__head').prev().offset().top - 100,
		});
	});
}

slideReviewForm();