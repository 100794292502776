function scrollToReviews() {
	offset = $($('.reviews')[0]).offset().top;

	$('html, body').animate({
		scrollTop: offset - 100,
	})
}

$('.js-scroll-to-reviews').on('click', function(e) {
	e.preventDefault();
	scrollToReviews();
});