import { $html } from '../utils/globals';

function ScrollToSection($animateScrollElement) {
	$('a').click(function(event){
		const $this = $(this);
		
		let _href = $this.attr('href');

		if(_href == undefined) {
			return false;
		}

		if(_href.indexOf('#') >= 0){
			let _hash = _href.split('#');

			if(_hash[1].indexOf('-') >= 0){
				let _prefix = _hash[1].split('-');
				let $id = $('#' + _hash[1]);

				if(_prefix[0] == 'section' && $id.length){
					event.preventDefault();

					let _offset = $id.offset().top;

					$animateScrollElement.delay(100).stop(true, true).animate({
						scrollTop: parseInt(_offset, 10)
					}, 1000);
				}
			}
		}
	});	
}

ScrollToSection($html);