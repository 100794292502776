import { $body } from '../utils/globals';
import { $header } from '../utils/globals';
import { $navTrigger } from '../utils/globals';

function mobileNavTrigger() {
	$navTrigger.on('click', function(e) {
		e.preventDefault();

		if (! $header.length) {
			return;
		}

		$body
			.toggleClass('show-nav');

		$header
			.find('.header__content')
				.stop()
					.slideToggle({
						start: function () {
							$(this).css({
								display: "flex"
							})
						}
					});
	});
}

mobileNavTrigger();