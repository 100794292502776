import StickySidebar from 'sticky-sidebar/dist/sticky-sidebar.min';
import { $win } from '../utils/globals';
import { $header } from '../utils/globals';

function initSticky(sidebar, sidebarInner) {
	if (window.innerWidth < 1025) {
		return;
	}

	const topSpacing = $('#wpadminbar').length > 0 ? $('#wpadminbar').outerHeight() : 0;

	new StickySidebar(sidebar, {
		containerSelector: sidebarInner,

		innerWrapperSelector: sidebarInner,

		topSpacing: topSpacing + 10,

		bottomSpacing: 0,

		resizeSensor: true,
	});
}

$win.on('load', function() {
	if ($('.article-single .article__sidebar').length < 1) {
		return;
	}
	
	initSticky('.article__sidebar', '.article__sidebar-inner');
});

$win.on('load', function() {
	if ($('.section-default .section__sidebar').length < 1) {
		return;
	}
	
	initSticky('.section__sidebar', '.section__sidebar-inner');
});