import { $body } from '../utils/globals';
import { $headerFormTrigger } from '../utils/globals';
import { $headerFormCloser } from '../utils/globals';

function triggerHeaderForm($trigger, $closer) {
	$trigger.on('click', function(e) {
		e.preventDefault();

		$body.addClass('show-header-form');
	});

	$closer.on('click', function(e) {
		e.preventDefault();

		$body.removeClass('show-header-form');
	});
}

triggerHeaderForm($headerFormTrigger, $headerFormCloser);